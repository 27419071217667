<template>
  <v-container fluid>
    <div class="pb-2 d-flex">
      <v-btn class="ma-1" @click.stop="getEstudios" color="primary" small>
        <v-icon small left>mdi-refresh</v-icon>
        Recargar
      </v-btn>
      <v-btn class="ma-1" to="/instalaciones" color="primary" small>
        <v-icon small left>mdi-solar-power-variant</v-icon>
        Control instalaciones
      </v-btn>
      <v-spacer></v-spacer>
    </div>

    <v-card>
      <v-data-table :headers="headers" :items="estudios" :loading="loading" :single-select="false" item-key="idEstudio"
        :calculate-widths="true" :footer-props="{
          showFirstLastPage: true,
          showCurrentPage: true,
          itemsPerPageOptions: [25, 50, -1],
        }">
        <template v-slot:body.prepend="{ headers }">
          <table-filters :headers="headers" :items="estudios" v-model="inlineFilters"></table-filters>
        </template>

        <template v-slot:item.instalacion.estado_actual_int.estado="{ item }">
          <status-chip v-if="item.instalacion.estado_actual_int.estado"
            :value="item.instalacion.estado_actual_int.estado" />
        </template>

        <template v-slot:item.instaladora.nombreInstaladora="{ item }">
          <v-chip outlined label v-if="item?.instaladora?.nombreInstaladora">
            <v-icon small left>mdi-wrench</v-icon>
            {{ item.instaladora.nombreInstaladora }}
          </v-chip>
        </template>

        <template v-slot:item.idEstudio="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-on="on" v-bind="attrs" color="secondary" :to="`/detalles_estudio?idEstudio=${item.idEstudio}`"
                text>
                <v-icon v-if="item.isAutoestudio" left>mdi-lightning-bolt</v-icon>
                {{ item.idEstudio }}</v-btn>
            </template>
            Detalles estudio {{ item.isAutoestudio ? "(Express)" : "" }}
          </v-tooltip>
        </template>

        <template v-for="{ idTramite, slotName } in slotsNames" v-slot:[slotName]="{ item }">
          <v-progress-circular v-if="updating[0] == item.instalacion.idInstalacion &&
            updating[1] == idTramite
          " indeterminate></v-progress-circular>

          <template v-else-if="item[idTramite]">
            <v-select dense hide-details outlined v-model="item[idTramite].idEstadoTramite" item-text="estado"
              item-value="idEstadoTramite" :items="item[idTramite]?.tramite.estados" @change="(v) => cambioEstado({
                idInstalacion: item.instalacion.idInstalacion,
                idTramite: idTramite,
                idEstadoTramite: v,
                observaciones: item[idTramite].observaciones
              })
                " :background-color="item[idTramite]?.estado?.color">
            </v-select>
            <v-textarea @change="(v) => cambioEstado(
              {
                idInstalacion: item.instalacion.idInstalacion,
                idTramite: idTramite,
                idEstadoTramite: item[idTramite].idEstadoTramite,
                observaciones: v
              }
            )
              " v-model="item[idTramite].observaciones" hide-details :rows="1" no-resize auto-grow outlined filled
              placeholder="Observaciones" />
          </template>
        </template>
      </v-data-table>
    </v-card>

    <!-- <v-dialog
      max-width="1200"
      :value="pagosId"
      @input="(v) => (v == false ? (pagosId = null) : null)"
    >
      <v-card :key="pagosId" class="pa-4">
        <PagosInstalacion :idEstudio="pagosId" tipo="instalacion" />
      </v-card>
    </v-dialog> -->
  </v-container>
</template>

<script>
import { perColumnFilter } from "@/utils/index.js";

export default {
  components: {
    TableFilters: () => import("@/components/TableFilters.vue"),
    StatusChip: () => import("@/components/StatusChip.vue"),
    PagosInstalacion: () =>
      import("@/modules/estudios/components/kanban/PagosInstalacion.vue"),
  },
  data() {
    return {
      menuTab: "pagos-pendiente",
      estudios: [],
      pagosId: null,
      loading: false,
      inlineFilters: {},
      tramites: [],
      money: Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" }),
      updating: [null, null],
    };
  },
  computed: {
    slotsNames() {
      return this.tramites.map((t) => ({
        idTramite: t.idTramite,
        slotName: `item.${t.idTramite}.estado.estado`,
      }));
    },
    headers() {
      return [
        {
          text: "Estado instalacion",
          value: "instalacion.estado_actual_int.estado",
          dataType: "select",
        },
        { text: "Nº Estudio", value: "idEstudio", align: "end" },
        { text: "Nombre estudio", value: "nombreProyecto" },
        {
          text: "Instaladora",
          value: "instaladora.nombreInstaladora",
          dataType: "select",
        },
        {
          text: "Comercial",
          value: "comercial.nombre",
          dataType: "select",
        },
        ...this.tramites.map((t) => ({
          text: t.nombre,
          value: `${t.idTramite}.estado.estado`,
          dataType: "select",
        })),
      ]
        .filter((h) => {
          return (
            (h.sub == null || h.sub) &&
            (h.acceso == null || this.$root.acceso(h.acceso))
          );
        })
        .map((header) => {
          return {
            class: "text-no-wrap sticky-header",
            cellClass: "pa-1 text-no-wrap",
            filter: (value) =>
              perColumnFilter(
                value,
                this.inlineFilters[header.value],
                header.dataType
              ),
            dataType: "text",
            ...header,
          };
        });
    },
  },
  methods: {
    async getTramites() {
      this.loading = true;
      const { data } = await axios({
        url: `${process.env.VUE_APP_API_URL}/tramite_instalaciones/tramites`,
      });

      this.tramites = data;
      this.loading = false;
    },
    async getEstudios() {
      this.loading = true;
      const { data } = await axios({
        url: `${process.env.VUE_APP_API_URL}/tramite_instalaciones`,
      });

      this.estudios = data;
      this.loading = false;
    },
    async cambioEstado({ idInstalacion, idTramite, idEstadoTramite, observaciones }) {
      this.updating = [idInstalacion, idTramite];
      try {
        await axios({
          url: `${process.env.VUE_APP_API_URL}/tramite_instalaciones/${idInstalacion}`,
          method: "PATCH",
          data: [{ idTramite, idEstadoTramite, observaciones }],
        });

        let idx = this.estudios.findIndex(e => e.instalacion.idInstalacion == idInstalacion)

        this.estudios[idx][idTramite].estado = this.estudios[idx][idTramite].tramite.estados.find(e => e.idEstadoTramite == idEstadoTramite)

        this.$root.$emit("snack", "Se han guardado los cambios");
      } catch {
        this.$root.$emit("snack", "Ha ocurrido un error inesperado", "error");
      } finally {
        this.updating = [null, null];
      }
    },
  },
  mounted() {
    this.getTramites();
  },
  watch: {
    menuTab: {
      handler() {
        this.estudios = [];
        this.getEstudios();
      },
      immediate: true,
    },
  },
};
</script>

