var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"pb-2 d-flex"},[_c('v-btn',{staticClass:"ma-1",attrs:{"color":"primary","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.getEstudios.apply(null, arguments)}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-refresh")]),_vm._v(" Recargar ")],1),_c('v-btn',{staticClass:"ma-1",attrs:{"to":"/instalaciones","color":"primary","small":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-solar-power-variant")]),_vm._v(" Control instalaciones ")],1),_c('v-spacer')],1),_c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.estudios,"loading":_vm.loading,"single-select":false,"item-key":"idEstudio","calculate-widths":true,"footer-props":{
        showFirstLastPage: true,
        showCurrentPage: true,
        itemsPerPageOptions: [25, 50, -1],
      }},scopedSlots:_vm._u([{key:"body.prepend",fn:function({ headers }){return [_c('table-filters',{attrs:{"headers":headers,"items":_vm.estudios},model:{value:(_vm.inlineFilters),callback:function ($$v) {_vm.inlineFilters=$$v},expression:"inlineFilters"}})]}},{key:"item.instalacion.estado_actual_int.estado",fn:function({ item }){return [(item.instalacion.estado_actual_int.estado)?_c('status-chip',{attrs:{"value":item.instalacion.estado_actual_int.estado}}):_vm._e()]}},{key:"item.instaladora.nombreInstaladora",fn:function({ item }){return [(item?.instaladora?.nombreInstaladora)?_c('v-chip',{attrs:{"outlined":"","label":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-wrench")]),_vm._v(" "+_vm._s(item.instaladora.nombreInstaladora)+" ")],1):_vm._e()]}},{key:"item.idEstudio",fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary","to":`/detalles_estudio?idEstudio=${item.idEstudio}`,"text":""}},'v-btn',attrs,false),on),[(item.isAutoestudio)?_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-lightning-bolt")]):_vm._e(),_vm._v(" "+_vm._s(item.idEstudio))],1)]}}],null,true)},[_vm._v(" Detalles estudio "+_vm._s(item.isAutoestudio ? "(Express)" : "")+" ")])]}},_vm._l((_vm.slotsNames),function({ idTramite, slotName }){return {key:slotName,fn:function({ item }){return [(_vm.updating[0] == item.instalacion.idInstalacion &&
          _vm.updating[1] == idTramite
        )?_c('v-progress-circular',{attrs:{"indeterminate":""}}):(item[idTramite])?[_c('v-select',{attrs:{"dense":"","hide-details":"","outlined":"","item-text":"estado","item-value":"idEstadoTramite","items":item[idTramite]?.tramite.estados,"background-color":item[idTramite]?.estado?.color},on:{"change":(v) => _vm.cambioEstado({
              idInstalacion: item.instalacion.idInstalacion,
              idTramite: idTramite,
              idEstadoTramite: v,
              observaciones: item[idTramite].observaciones
            })},model:{value:(item[idTramite].idEstadoTramite),callback:function ($$v) {_vm.$set(item[idTramite], "idEstadoTramite", $$v)},expression:"item[idTramite].idEstadoTramite"}}),_c('v-textarea',{attrs:{"hide-details":"","rows":1,"no-resize":"","auto-grow":"","outlined":"","filled":"","placeholder":"Observaciones"},on:{"change":(v) => _vm.cambioEstado(
            {
              idInstalacion: item.instalacion.idInstalacion,
              idTramite: idTramite,
              idEstadoTramite: item[idTramite].idEstadoTramite,
              observaciones: v
            }
          )},model:{value:(item[idTramite].observaciones),callback:function ($$v) {_vm.$set(item[idTramite], "observaciones", $$v)},expression:"item[idTramite].observaciones"}})]:_vm._e()]}}})],null,true)})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }